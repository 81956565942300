import { createElement, useRef } from "react";
import AnimationContainer from "~components/ui/animation-container";
import useBreakpoint from "~hooks/use-breakpoint";
import Heading from "../../ui/heading";
import ArrowButton from "./arrow-button";
import blocks from "./blocks";
import defaultData from "./data";

const getClasses = (locale, n) => {
  const classes = {
    title: "mb-2 text-lg font-semibold md:text-3xl",
    description: "text-sm !leading-snug md:text-lg",
    root: "md:p-6 p-4 transition-transform cursor-default hover:scale-95",
  };

  return {
    root: `${classes.root} ${`better-grid-block-${n}`}`,
    title: `${classes.title} ${
      locale === "en" ? "!font-medium" : "font-semibold"
    }`,
    description: `${classes.description} ${
      locale === "en" ? "font-normal" : "font-medium"
    }`,
  };
};

const BetterBlock = ({ data = defaultData, locale, className }) => {
  const ref = useRef(null);
  const isXl = useBreakpoint("xl");
  const { title, title_image, description, items } = data;

  const handleScroll = (direction) => {
    let value = isXl ? 10000 : 200;
    if (!isXl && !ref.current.scrollLeft) value += 20;
    if (direction === "left") value = -value;
    ref.current.scrollLeft += value;
  };

  return (
    <section
      className={`bg-gray-back pt-20 pb-20 md:pt-28 md:pb-28 ${className}`}
    >
      <div className="landing-container">
        <AnimationContainer className="mb-16 text-center md:mb-24" delay={150}>
          <Heading
            variant="h2"
            image={title_image}
            locale={locale}
            className="mb-5"
          >
            {title}
          </Heading>
          <p className="text-lg md:text-2xl">{description}</p>
        </AnimationContainer>
      </div>
      <AnimationContainer delay={150}>
        <div
          ref={ref}
          className="better-grid mb-3 overflow-x-auto scroll-smooth"
        >
          {items.map((item, index) =>
            createElement(blocks[index], {
              ...item,
              key: item.title,
              classes: getClasses(locale, index + 1),
            }),
          )}
        </div>
        <div className="landing-container flex justify-center md:justify-start xl:px-0">
          <ArrowButton
            direction="left"
            onClick={() => handleScroll("left")}
            className="mr-3"
          />
          <ArrowButton
            direction="right"
            onClick={() => handleScroll("right")}
          />
        </div>
      </AnimationContainer>
    </section>
  );
};

export default BetterBlock;
