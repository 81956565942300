import Block1 from "./block-1";
import Block2 from "./block-2";
import Block3 from "./block-3";
import Block4 from "./block-4";
import Block5 from "./block-5";
import Block6 from "./block-6";
import Block7 from "./block-7";
import Block8 from "./block-8";

const blocks = [Block1, Block2, Block3, Block4, Block5, Block6, Block7, Block8];

export default blocks;
