// import Image from "next/image";
// import AllIcons from "~components/ui/all-icons";

const Block1 = ({ title, description, image, classes }) => {
  return (
    <div
      className={`relative flex flex-col rounded-2xl bg-black/95 text-white md:rounded-3xl !p-0 overflow-hidden ${classes.root}`}
    >
      <div className="max-w-3/4 ">{image && <img alt="" src={image} />}</div>
      <div className="p-4 md:p-6">
        <div className={classes.title}>{title}</div>
        <p className={classes.description}>{description}</p>
      </div>
      {/* <AllIcons name="LogoCircle" className="absolute top-6 left-6 h-8 w-8" /> */}
    </div>
  );
};

export default Block1;
