import LandingNavbar from "../ui/navbar";
import LandingFooter from "../ui/footer";

const LandingLayout = ({ header, footer, locale, children }) => (
  <main
    className={`landing overflow-hidden ${
      locale === "en" ? "font-clash" : "font-montserrat"
    }`}
  >
    <LandingNavbar data={header} />
    <div>{children}</div>
    <LandingFooter data={footer} />
  </main>
);

export default LandingLayout;
