import Button from "../../ui/button";

const ArrowButton = ({ direction, onClick, className }) => {
  return (
    <Button
      variant="outline"
      className={`arrow-button relative h-[60px] w-[60px] !border-gray-400 !py-0 !px-3 transition-all hover:w-[120px] hover:!border-black hover:bg-black active:scale-90 ${className}`}
      onClick={onClick}
    >
      <div className="h-0.5 w-full bg-gray-400" />
      <div
        className={`absolute top-[16px] h-[24px] w-[24px] rotate-45 border-2 border-gray-400 ${
          direction === "left"
            ? "left-[14px] border-t-0 border-r-0"
            : "right-[14px] border-b-[0] border-l-0"
        }`}
      />
    </Button>
  );
};

export default ArrowButton;
