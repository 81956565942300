import { useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import VisibilitySensor from "react-visibility-sensor";
import * as easings from "d3-ease";

const commonEasing = easings.easeCubicOut;

// EASINGS
// easings.easeCircle
// easings.easeElastic
// easings.easeCubicIn
// easings.easeQuadIn
// easings.easeQuad

const AnimationLayout = ({
  isVisible,
  children,
  className,
  animation = "fadeInToBottom",
  config = { duration: 600 },
  delay,
}) => {
  const animationProps = useSpring(
    propsObject[animation]({ isVisible, config, delay }),
  );

  return (
    <animated.div className={className} style={animationProps}>
      {children}
    </animated.div>
  );
};

const AnimationContainer = ({
  children,
  className,
  animation,
  delay = 150,
  config,
}) => {
  const [isVisible, setVisibility] = useState(false);

  const onChange = (visiblity) => visiblity && setVisibility(visiblity);

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 100 }}
      onChange={onChange}
    >
      <AnimationLayout
        className={className}
        isVisible={isVisible}
        animation={animation}
        delay={delay}
        config={config}
      >
        {children}
      </AnimationLayout>
    </VisibilitySensor>
  );
};

export default AnimationContainer;

const propsObject = {
  fadeIn: ({ isVisible, config, delay }) => ({
    opacity: isVisible ? 1 : 0,
    config: { ...config, easing: commonEasing },
    delay,
  }),
  fadeInToBottom: ({ isVisible, config, delay }) => ({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0px)" : "translateY(-50px)", /// / NOTE Translate X not work on mobile divices
    config: { ...config, easing: commonEasing },
    delay,
  }),
  scaleIncrease: ({ isVisible, config, delay }) => ({
    transform: isVisible ? "scale(1)" : "scale(0.8)",
    opacity: isVisible ? 1 : 0,
    config: { ...config, easing: commonEasing },
    delay,
  }),
};
