const variants = {
  default: "border-2 border-transparent bg-black text-white",
  outline: "border-2 border-black text-black",
};

const Button = ({ variant = "default", onClick, className, children }) => {
  return (
    <button
      onClick={onClick}
      className={`box-border whitespace-nowrap rounded-full px-5 py-2 font-semibold transition-all hover:scale-95 ${variants[variant]} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
