import Image from "next/image";

const Block8 = ({ title, description, image, classes }) => {
  return (
    <div
      className={`flex flex-col overflow-hidden rounded-tl-2xl rounded-br-2xl bg-red-base text-white md:rounded-tl-3xl md:rounded-br-3xl ${classes.root}`}
    >
      <div className="relative mb-6 h-full w-full translate-x-[15%]">
        {image && (
          <Image alt="" src={image} layout="fill" objectFit="contain" />
        )}
      </div>
      <div className="mt-auto">
        <div className={classes.title}>{title}</div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default Block8;
