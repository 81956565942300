import Link from "next/link";
import Button from "~components/ui/button";
import AllIcons from "~components/ui/all-icons";
import useBreakpoint from "src/hooks/use-breakpoint";
import LocaleButton from "./locale-button";

const LandingNavbar = ({ data = {} }) => {
  const { button } = data;
  const isMd = useBreakpoint("md");

  return (
    <div
      className={
        "t-0 relative z-30 flex w-screen items-center justify-between bg-white py-4 px-4 md:px-5"
      }
    >
      <div className="relative flex items-center">
        <Link href="/">
          <a className="transition-all hover:text-pink-base">
            <AllIcons name="LogoFull" className="fill-icon w-24 md:w-40" />
          </a>
        </Link>
        {isMd && (
          <div className="rounded bg-pink-base px-1.5 py-0 text-xs font-clash font-medium text-white">
            Beta
          </div>
        )}
      </div>
      <div className="flex items-center justify-end">
        <div className="ml-5 flex items-center">
          <LocaleButton />
          <Button
            className="flex items-center justify-center whitespace-nowrap ml-4"
            href="/profile"
            variant="rounded"
          >
            {!isMd ? button?.split(" ")?.[0] : button}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingNavbar;
