const data = {
  title: "What makes us Better",
  description: "Subclub solves most of the problems that content creators face",
  items: [
    {
      title: "Reliability",
      description:
        "Subclub does not have access to your funds. All payments go directly to your crypto wallet and are stored on a third-party platform",
      image: "/assets/images/background.jpg",
    },
    {
      title: "Anonymity",
      description:
        "The platform does not have access to your personal data, and your subscribers can support you anonymously and without registration",
      image: "/assets/images/background.jpg",
    },
    {
      title: "High resolution ∞ No limit",
      description:
        "Unlike most services, we do not compress or spoil the media you upload. In our cloud storage you can buy as much space as you need, upload materials of any format and size to it and use it as you like",
      image: "/assets/images/background.jpg",
    },
    {
      title: "No KYC",
      description:
        "You do not need to pass verification and link any bank details to your account",
      button: "Connect wallet",
    },
    {
      title: "No restrictions",
      description:
        "Unlike conventional bank cards, cryptocurrency can be used to make transactions all over the world without prohibitions, restrictions on withdrawal and high commissions",
      image: "/assets/images/background.jpg",
    },
    {
      title: "Web3",
      description:
        "Using Subclub, you earn in cryptocurrency. Payment takes place in the BNB blockchain network",
      image: "/assets/images/background.jpg",
    },
    {
      title: "",
      description:
        "Thanks to the blockchain, we are able to provide the lowest commissions posible",
      image: "/assets/images/background.jpg",
    },
    {
      title: "Customization",
      description:
        "You can create multiple subscriptions at different prices to provide subscribers with different types of content",
      image: "/assets/images/background.jpg",
    },
  ],
};

export default data;
