import { createElement, Fragment } from "react";
import useBreakpoint from "~hooks/use-breakpoint";
import Markdown from "~ui/markdown";

const classes = {
  font: {
    default: {
      h1: "text-4xl md:text-5xl font-montserrat font-bold uppercase md:!leading-[78px]",
      h2: "text-4xl md:text-5xl font-montserrat font-bold",
    },
    en: {
      h1: "text-3xl md:text-5xl font-heading uppercase md:!leading-[78px]",
      h2: "text-3xl md:text-4xl font-heading uppercase",
    },
  },
};

const getClasses = ({ variant, image, locale, className }) => {
  const arr = [];
  const currentLocale = locale === "en" ? "en" : "default";
  if (className) arr.push(className);
  if (!image) arr.push(classes.font[currentLocale][variant]);

  return arr.join(" ");
};

const Heading = ({
  image,
  variant = "h1",
  locale = "ru",
  children,
  className,
  imageClassName,
  ...props
}) => {
  const isMd = useBreakpoint("md");
  const imageUrl = image?.desktop || image?.mobile || null;

  return createElement(
    variant,
    {
      ...props,
      className: getClasses({ variant, image: imageUrl, locale, className }),
    },
    imageUrl ? (
      <img
        src={image[isMd ? "desktop" : "mobile"] || imageUrl}
        className={imageClassName}
      />
    ) : (
      <Markdown
        components={{
          p: Fragment,
          strong: ({ node, ...props }) => (
            <span
              className={locale === "en" && "font-heading-wide"}
              {...props}
            />
          ),
          img: ({ src, alt }) => (
            <img
              src={src}
              alt={alt}
              className="w-[70px] h-[70px] md:w-[90px] md:h-[90px] inline-block"
            />
          ),
        }}
      >
        {children}
      </Markdown>
    ),
  );
};

export default Heading;
