import Image from "next/image";

const Block6 = ({ title, description, image, classes }) => {
  return (
    <div
      className={`relative flex overflow-hidden rounded-tr-2xl rounded-bl-2xl bg-purple-base text-white md:rounded-b-3xl md:rounded-tr-3xl ${classes.root}`}
    >
      <div className="absolute -right-4 -top-28 h-full w-2/3">
        {image && (
          <Image alt="" src={image} layout="fill" objectFit="contain" />
        )}
      </div>
      <div className="relative mt-auto flex flex-col">
        <div
          className={`!mb-4 self-start rounded-full bg-white py-1 px-3 text-black ${classes.title}`}
        >
          {title}
        </div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default Block6;
