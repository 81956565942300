import Image from "next/image";

const Block2 = ({ title, description, image, classes }) => {
  return (
    <div
      className={`flex flex-col rounded-t-2xl rounded-br-2xl bg-pink-base text-white md:rounded-t-3xl md:rounded-br-3xl ${classes.root}`}
    >
      <div className="relative ml-auto mb-6 w-full grow">
        {image && (
          <Image alt="" src={image} layout="fill" objectFit="contain" />
        )}
      </div>
      <div>
        <div className={classes.title}>{title}</div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default Block2;
