import dynamic from "next/dynamic";

const _Stories = dynamic(() => import("react-insta-stories"), { ssr: false });

const Stories = ({ stories, width, height, className }) => {
  return (
    <div className={`stories-container ${className}`}>
      <_Stories loop stories={stories} width={width} height={height} />
    </div>
  );
};

export default Stories;
