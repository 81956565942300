import Image from "next/image";

const Block5 = ({ title, description, image, classes }) => {
  return (
    <div
      className={`relative flex overflow-hidden rounded-b-2xl rounded-tr-2xl bg-orange-base text-white md:rounded-b-3xl md:rounded-tr-3xl ${classes.root}`}
    >
      {image && (
        <Image
          alt=""
          src={image}
          layout="fill"
          objectFit="cover"
          className="opacity-75 mix-blend-overlay contrast-75"
        />
      )}

      <div className="relative mt-auto">
        <div className={classes.title}>{title}</div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default Block5;
