import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";

const Markdown = ({ children, ...props }) => {
  return (
    <ReactMarkdown {...props} remarkPlugins={[remarkGfm, remarkBreaks]}>
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
