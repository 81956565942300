import Image from "next/image";

const Block7 = ({ description, image, classes }) => {
  return (
    <div
      className={`flex flex-col rounded-b-2xl rounded-tl-2xl bg-gray-200 md:rounded-b-3xl md:rounded-tl-3xl ${classes.root}`}
    >
      <div className="relative mb-6 h-full w-full">
        {image && (
          <Image alt="" src={image} layout="fill" objectFit="contain" />
        )}
      </div>
      <div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default Block7;
