import Image from "next/image";

const Block4 = ({ title, description, image, classes }) => {
  return (
    <div
      className={`flex flex-col rounded-tr-2xl rounded-bl-2xl bg-green-base md:rounded-tr-3xl md:rounded-bl-3xl ${classes.root}`}
    >
      <div className="mb-6 grow">
        <div className={classes.title}>{title}</div>
        <p className={classes.description}>{description}</p>
      </div>
      {/* <Link href="/home">
        <Button className="w-full !bg-pink-base !px-0 !text-xs transition-all hover:!border-black hover:!bg-transparent hover:!text-black md:!text-lg">
          {button}
        </Button>

      </Link> */}
      {image && (
        <Image
          alt=""
          src={image}
          width={4}
          height={1}
          layout="responsive"
          objectFit="contain"
        />
      )}
    </div>
  );
};

export default Block4;
